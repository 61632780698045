import React, { useState } from "react";
import { Typography, Button, Menu, MenuItem } from "@mui/material";
import { useDisconnect } from "wagmi";
import { makeStyles } from "@mui/styles";

import { getAdminName } from "../constants";
import { Link } from "react-router-dom";

type Props = {
  isLoggedIn: boolean;
  address: string;
};

const Navbar = (props: Props) => {
  const { isLoggedIn, address } = props;
  const { disconnect } = useDisconnect();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!isLoggedIn) return null;
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography>Welcome {getAdminName(address)}!</Typography>
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          variant="contained"
        >
          Menù
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleClose}>
            <Link to="/">Home</Link>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Link to="/users">Users</Link>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Link to="/art">Artworks</Link>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Link to="/insights">Insights</Link>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Link to="/invoice">Invoice</Link>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Link to="/charts">Charts</Link>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Link to="/screens">Screens</Link>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Link to="/createEditorial">Editorial</Link>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Link to="/collections">Collections</Link>
          </MenuItem>
        </Menu>
        <Button
          onClick={() => {
            disconnect();
            localStorage.removeItem("signature");
          }}
          variant="contained"
          color="error"
        >
          Logout
        </Button>
      </div>
    </div>
  );
};

export default Navbar;

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    maxWidth: "1920px",
    padding: "1rem 2rem",
  },
}));
